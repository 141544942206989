// - Order/OrderWhere.js

import React, { Component } from 'react'
import { FadeLoader } from 'react-spinners'
import Success from './Success'
import Failed from './Failed'

class SimplePayBackref extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            transactionID: null,
            success: null,
            status: null,
            loading: true
        }
    }
    setOrderData = () => {
        let statusCode = this.props.statusCode
        let simplePayTransactionID = this.props.simplePayTransactionID

        switch(statusCode) {
        case "SUCCESS":
            this.setState({
                loading: false,
                success: true,
                transactionID: simplePayTransactionID,
            })
        break
        case "FAIL":
            this.setState({
                loading: false,
                success: false,
                transactionID: simplePayTransactionID,
                status: "FAIL"
            })
        break
        case "CANCEL":
            this.setState({
                loading: false,
                success: false,
                transactionID: simplePayTransactionID,
                status: "CANCEL"
            })
        break
        case "TIMEOUT":
            this.setState({
                loading: false,
                success: false,
                transactionID: simplePayTransactionID,
                status: "TIMEOUT"
            })
        break
        default:
            this.setState({
                loading: false,
                success: false,
                status: "ERROR"
            })
            break
        }
    }
    componentDidMount() {
        this.setOrderData()
    }
    render() {
        return (
            <div className="simplePayBackrefContainer">
                {this.state.loading && <div className="loadingContainer"><FadeLoader
                    color={'grey'}
                    loading={true}
                /></div>}
                {!this.state.loading && this.state.success && <Success dictionary={this.props.dictionary} selectedLanguage={this.props.selectedLanguage} transactionID={this.state.transactionID} />}
                {!this.state.loading && !this.state.success && <Failed dictionary={this.props.dictionary} selectedLanguage={this.props.selectedLanguage} transactionID={this.state.transactionID} status={this.state.status}/>}
            </div>
        )
    }
}

export default SimplePayBackref
  